import React, { Fragment, Component } from "react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { ContactWrapper } from "../components/Style/contact"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactPage extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      selectOption: "select",
      message: "",
      thankyou: false,
      error: {
        nameError: false,
        emailError: false,
        selectError: false,
        messageError: false,
      },
    }
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { name, email, selectOption, message } = this.state
    let hasError = false
    console.log({ hasError })

    if (name === "" || email === "" || selectOption === "select" || message === "") {
      hasError = true
      if (name === "") {
        this.setState({ error: { nameError: true } })
      } else if (email === "") {
        this.setState({ error: { emailError: true } })
      } else if (selectOption === "select") {
        this.setState({ error: { selectError: true } })
      } else if (message === "") {
        this.setState({ error: { messageError: true } })
      }
    }

    if (!hasError) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state }),
      })
        .then(() => this.setState({ thankyou: true }))
        .catch((error) => {
          console.log("ooops something went wrong..")
        })
    }
  }

  render() {
    const { name, email, selectOption, message, thankyou, error } = this.state
    const isInvalid = name === "" || email === "" || selectOption === "" || selectOption === "select" || message === ""

    return (
      <Fragment>
        <Seo title={`Contact Us`} description={`We'd love to hear from our players. Please use the contact form to contact us.`} />

        <Layout>
          <ContactWrapper>
            <div className="inner-wrapper">
              <div className="heading-section">
                <h1>Contact Us</h1>
                <p>Got a question? Don't see a league near you?</p>
                <p>Send us a message and we'll get back to you as soon as possible!</p>
              </div>
              {thankyou ? (
                <p className="success-message">
                  Your message has been sent!{" "}
                  <span role="img" aria-label="rocket emoji">
                    🚀
                  </span>
                </p>
              ) : (
                <form onSubmit={(event) => this.handleSubmit(event)} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                  <div className="form-inner-wrapper">
                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="name">
                        Name:
                      </label>
                      <input id="name" type="text" name="name" placeholder="Your name" value={name} onChange={this.onChange} />
                      {error["nameError"] && (
                        <div className="error-message">
                          <p>Name field is required</p>
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="email">
                        Email:
                      </label>
                      <input id="email" type="email" name="email" placeholder="Your email" value={email} onChange={this.onChange} />
                      {error["emailError"] && (
                        <div className="error-message">
                          <p>Email field is required</p>
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="subject">
                        Type of enquiry:
                      </label>
                      <select id="subject" name="selectOption" value={selectOption} onChange={this.onChange}>
                        <option disabled value="select">
                          Type of enquiry
                        </option>
                        <option value="general-enquiry">General Enquiry</option>
                        <option value="league-enquiry">League Enquiry</option>
                      </select>
                      {error["selectError"] && (
                        <div className="error-message">
                          <p>Type of enquiry required</p>
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="message">
                        How can we help?
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        cols="30"
                        rows="10"
                        placeholder="How can we help?"
                        value={message}
                        onChange={this.onChange}
                      ></textarea>
                      {error["messageError"] && (
                        <div className="error-message">
                          <p>Message field is required</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <input type="hidden" name="form-name" value="contact" />
                  <button type="submit" disabled={isInvalid}>
                    Submit!
                  </button>
                </form>
              )}
            </div>
          </ContactWrapper>
        </Layout>
      </Fragment>
    )
  }
}

export default ContactPage
